(function () {
    'use strict';

    var MODES = {
        VIEW: 0,
        ADD: 1,
        EDIT: 2,
        ADDPROJECT: 3,
        EDITPROJECT: 4
    };

    var modeTitles = {
        0: "View",
        1: "Create",
        2: "Edit",
        3: "Create Project",
        4: "Edit Project"
    };

    angular.module('aerosApp')
        .factory('AddEditToolModal', AddEditToolModal);

    AddEditToolModal.$inject = ['$uibModal', 'ToolsDefinitionService', 'PresetService', 'aerosApi'];

    function AddEditToolModal($uibModal, ToolsDefinitionService, PresetService, aerosApi) {
        function open(toolModel, toolDescription, presets, mode, organizationID, projectID) {
            // We need to ensure the toolType is set on the model. There are places within the code that look for this,
            // see aflModalCarouselInput for one case. This also needs to be done before other initialization within
            // this routine, as it is referenced there too.
            if (!toolModel.toolType) {
                toolModel.toolType = toolDescription.type;
            }
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/tools/AddEditToolModal.html",
                controller: AddEditToolModalController,
                resolve: {
                    toolModel: function () {
                        return ToolsDefinitionService.format.nested2flat(toolModel);
                    },
                    toolDescription: function () {
                        return toolDescription;
                    },
                    mode: function () {
                        return mode;
                    },
                    organizationID: function () {
                        return organizationID;
                    },
                    projectID: function () {
                        return projectID;
                    },
                    presets: function () {
                        var presetService = PresetService(toolDescription.type);
                        return presetService.fetch(organizationID).then(function (presets) {
                            return presets[toolDescription.type];
                        });
                    },
                    tools: function () {
                        return aerosApi.getOrgToolsByType({
                            orgId: organizationID,
                            toolType: toolDescription.type.toLowerCase()
                        })
                    }
                }
            });
            return modal.result;
        }

        return {
            open: open,
            modes: MODES
        };
    }

    AddEditToolModalController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'ToolService', 'toolModel', 'toolDescription', '$rootScope',
        'presets', 'mode', 'tools', 'organizationID', 'projectID', 'UserProfileService', 'ToolsDefinitionService', 'ToolLibraryService', 'UnitsMeasurementService'];

    function AddEditToolModalController($scope, $uibModalInstance, Notification, ToolService, toolModel, toolDescription, $rootScope, presets, mode, tools,
                                        organizationID, projectID, UserProfileService, ToolsDefinitionService, ToolLibraryService, UnitsMeasurementService) {
        angular.extend($scope, {
            setFromPreset: setFromPreset,
            savePreset: savePreset,
            showCurrentTab: showCurrentTab,
            fieldChangeEventHandler: fieldChangeEventHandler,
            propertyChangeEventHandler: propertyChangeEventHandler,
            saveToolLibrary: saveToolLibrary,
            isVisibleItem: isVisibleItem,
            findDescriptionByName: ToolsDefinitionService.findDescriptionByName
        });

        function setUnitFields() {
            var units = {
                Meters: {
                    name: "meters"
                },
                Kilometers: {
                    name: "meters"
                },
                Miles: {
                    name: "miles",
                    toMeters: UnitsMeasurementService.milesToMeters,
                    fromMeters: UnitsMeasurementService.metersToMiles
                },
                Kilofeet: {
                    name: "feet",
                    toMeters: UnitsMeasurementService.feetToMeters,
                    fromMeters: UnitsMeasurementService.metersToFeet
                },
                Feet: {
                    name: "feet",
                    toMeters: UnitsMeasurementService.feetToMeters,
                    fromMeters: UnitsMeasurementService.metersToFeet
                }

            };

            var userProfile = UserProfileService.get();

            $scope.toolModel.units = units[userProfile.user.preferredUnitOfMeasure].name;
            $scope.toolModel.lossDistanceUnit = userProfile.user.preferredLossDistanceUnit;
            $scope.units = units[userProfile.user.preferredUnitOfMeasure];
        }


        (function init() {
            $scope.form = {};
            if (toolModel) {
                $scope.toolModel = toolModel;
            } else {
                $scope.toolModel = {};
            }
            $scope.toolDescription = toolDescription;
            $scope.presets = presets;
            $scope.mode = mode;
            $scope.modalTitle = (toolDescription.name === 'OTDR Tool') ? modeTitles[mode] + " " + toolDescription.name : "Create " + toolDescription.name;
            $scope.generic = getGeneric(tools.data.tools);
            // Don't propogate the generic name into the form
            $scope.generic.Singlemode.name = '';
            $scope.generic.Multimode.name = '';
            $scope.saveAsPresetOpened = [];
            $scope.selectedPreset = [];
            $scope.showTab = {};
            $scope.showTab[$scope.toolDescription.groups[0].id] = $scope.toolDescription.groups[0];
            $scope.newPresetsName = [];
            $scope.fields = [];

            setUnitFields();

            $scope.modes = MODES;
        })();

        function getGeneric(tools) {
            var generic = {};
            angular.forEach(tools, function (tool) {
                if (tool.generic) {
                    generic[tool.fiberMode] = ToolsDefinitionService.format.nested2flat(tool)
                }
            });
            return generic;
        }

        function setFromPreset(selected, key) {
            if (selected) {
                var obj = angular.fromJson(selected);

                if (!$scope.toolModel[key]) {
                    $scope.toolModel[key] = {};
                }

                for (var field in obj.fields) {
                    $scope.toolModel[key][field] = obj.fields[field];
                }
            }
        }

        function showCurrentTab(group) {
            if (!$scope.showTab) {
                $scope.showTab = {};
            }

            $scope.showTab[group.id] =
                $scope.showTab[group.id] === group ? undefined : group;
        }

        function savePreset(group) {
            var newPreset = {name: $scope.newPresetsName[group.id]};
            newPreset.fields = {};

            for (var groupItem in group.fields) {
                newPreset.fields[group.fields[groupItem].fieldName] = $scope.fields[group.fields[groupItem].fieldName];
            }

            $scope.presets[group.id].push(newPreset);

            var newPresetSaved = newPreset ;
            var firstPreset = $scope.presets[group.id][0];
            var index = $scope.presets[group.id].indexOf(newPresetSaved) ;
            $scope.presets[group.id][index] = firstPreset ;
            $scope.presets[group.id][0] = newPresetSaved ;


            $scope.newPresetsName[group.id] = '';
            $scope.selectedPreset[group.id] = angular.toJson(newPreset);

            Notification.success('Preset saved successfully');
            $scope.saveAsPresetOpened[group.id] = false;
        }

        function fieldChangeEventHandler(fields, presets, group) {
            var found = false;

            angular.forEach(presets[group.id], function (preset) {
                if (ToolService.compareModelFieldWithPreset(fields, preset.fields)) {
                    found = true;
                    $scope.selectedPreset[group.id] = angular.toJson(preset);
                }
            });

            if (!found) {
                $scope.selectedPreset[group.id] = '';
            }
        }

        function propertyChangeEventHandler(properties) {
            var children = ToolsDefinitionService.findChildren(properties.fieldName, toolDescription);

            if (children.length > 0) {
                angular.forEach(children, function (child) {
                    if (child.fieldDescription.findNearestOnParentChange) {
                        ToolsDefinitionService.updateModelWithNearestValue(child, properties.fieldName, $scope.toolModel,
                            properties.fieldDescription);
                    }
                });
            }
        }

        function updateWithUUID() {
            $scope.toolModel.uuid = "" + Math.random();

            angular.forEach(toolDescription.groups, function (value, key) {
                if ($scope.toolModel[value.id]) {
                    $scope.toolModel[value.id].uuid = "" + Math.random();

                    if (!_.isEmpty($scope.selectedPreset) && $scope.selectedPreset[value.id] !== '') {
                        $scope.toolModel[value.id].name = angular.fromJson($scope.selectedPreset[value.id]).name;
                    } else {
                        $scope.toolModel[value.id].name = "'" + $scope.toolModel.name + "'s " + value.id;
                    }
                } else {
                    $scope.toolModel[value.id] = {
                        uuid: "" + Math.random(),
                        name: "'" + $scope.toolModel.name + "'s " + value.id
                    };
                }
            });
        }

        function copyObject(object){
            return JSON.parse(JSON.stringify(object));
        }

        function saveToolLibrary() {
            var toolModel = angular.copy($scope.toolModel);

            switch ($scope.mode) {
                case MODES.ADD:
                    updateWithUUID();
                    ToolLibraryService.create($scope.toolDescription.type, organizationID, $scope.toolModel)
                        .then(function (results) {
                            $rootScope.$broadcast('toolLibListUpdate');
                            $uibModalInstance.close($scope.toolModel);
                        }, function (error) {
                            Notification.error(error.data.message + ": " + error.data.detail);
                        });
                    break;
                case MODES.EDIT:
                    ToolService.updateToolLibrary($scope.toolDescription.type, organizationID, {
                        library: $scope.toolModel
                    }).then(function (results) {
                        $uibModalInstance.close($scope.toolModel);
                    }, function (error) {
                        Notification.error(error.data.message + ": " + error.data.detail);
                    });
                    break;
                case MODES.ADDPROJECT:
                    $scope.toolCopy = copyObject($scope.toolModel);
                    ToolService.addTool($scope.toolDescription.type, organizationID, projectID, {
                        tool: $scope.toolCopy
                    }).then(function (results) {
                        $uibModalInstance.close($scope.toolModel);
                    }, function (error) {
                        Notification.error(error.data.message + ": " + error.data.detail);
                    });
                    break;
                case MODES.EDITPROJECT:
                    ToolService.updateTool($scope.toolDescription.type, organizationID, projectID, {
                        tool: toolModel,
                        overwrite: false
                    }).then(function (results) {
                        $uibModalInstance.close(results);
                    }, function (error) {
                        $uibModalInstance.close({error: error, tool: $scope.toolModel})
                    });
                    break;
            }
        }

        function isVisibleItem(description, toolModel) {
            return description['type'] === 'hidden' ? false : !description.parent ||
                (description.parent && description.values[toolModel[description.parent]]);
        }

        $scope.setForm = function (form) {
            $scope.form = form;
        }
    }
})();
